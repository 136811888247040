import 'bootstrap/js/dist/modal';
import './main.scss';

(function() {
  if (window._kraftkarre && window._kraftkarre['fahrzeugbewertung']) {
    loadFahrzeugbewertung();
  }

  const observer = new MutationObserver(function() {
    if (window._kraftkarre['fahrzeugbewertung'] === true && document.querySelector('[kk-fahrzeugbewertung-modal="true"]')) {
      document.querySelector('[kk-fahrzeugbewertung-modal="true"]').setAttribute('data-bs-toggle', 'modal');
      document.querySelector('[kk-fahrzeugbewertung-modal="true"]').setAttribute('data-bs-target', '#kk-fahrzeugbewertung-modal');
    }
  });

  observer.observe(document.body, {subtree: true, childList: true});
})();

function loadFahrzeugbewertung() {
  const iframeElement = document.createElement('iframe');

  iframeElement.setAttribute('id', 'kk-iframe');
  iframeElement.setAttribute('src', `${process.env.REACT_APP_WWW_HOST}/widget/fahrzeugbewertung?apiKey=${window._kraftkarre['apiKey']}`);
  iframeElement.setAttribute('style', 'border: none; width:100%; height: 99%; margin: 0, padding: 0;');

  const divModalElement = document.createElement('div');
  divModalElement.setAttribute('id', 'kk-fahrzeugbewertung-modal');
  divModalElement.setAttribute('data-bs-backdrop', 'static');
  divModalElement.setAttribute('data-bs-keyboard', 'false');
  divModalElement.setAttribute('tabindex', '-1');
  divModalElement.setAttribute('aria-labelledby', 'kk-fahrzeugbewertung-modal-label');
  divModalElement.setAttribute('aria-hidden', 'true');
  divModalElement.classList.add('modal');
  divModalElement.classList.add('fade');

  divModalElement.innerHTML = `
    <div class="modal-dialog modal-fullscreen-lg-down modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body kk-fahrzeugbewertung-modal-body" style="margin:0; padding:0; height:1000px;"></div>
      </div>
    </div>
  `;

  divModalElement.querySelector('.kk-fahrzeugbewertung-modal-body').appendChild(iframeElement);
  document.body.appendChild(divModalElement);
}